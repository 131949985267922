<template>
  <a-drawer
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    width="100%"
    :title="$t('TrainingProgramDetails')"
  >
    <div class="detail">
      <div class="title-wrap">{{ $t("lecturer.basic_info") }}</div>
      <a-form
        class="edit-form-wrap"
        layout="vertical"
        ref="formRef"
        :model="formData"
      >
        <!-- <a-row :gutter="24">
        <a-col :span="12">
          <secret
            :value="formData.secretData"
            :disabled="disabled"
            @secret:change="(e) => (formData.secretData = e)"
          />
        </a-col>
      </a-row> -->
        <a-row :gutter="24">
          <template v-for="(item, index) in state.editFields" :key="index">
            <template v-if="item.defaultField == 1">
              <a-col :span="12" v-if="item.key == 'planName'">
                <a-form-item :label="item.name" name="planName">
                  <a-input
                    :maxlength="50"
                    v-model:value="formData.planName"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
              <a-col
                :span="12"
                v-if="
                  item.key == 'demandCollection' &&
                  state.companyInfo.menu.includes('需求征集管理')
                "
              >
                <a-form-item :label="item.name" name="demandCollectionName">
                  <a-input
                    v-model:value="formData.demandCollectionName"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col
                :span="12"
                v-if="
                  item.key == 'demand' &&
                  state.companyInfo.menu.includes('需求征集管理')
                "
              >
                <a-form-item :label="item.name" name="planName">
                  <a-select
                    v-model:value="formData.demandIds"
                    mode="multiple"
                    :open="false"
                    style="width: 100%"
                    disabled
                  >
                    <a-select-option
                      :value="item.demandId"
                      v-for="item in state.demands"
                      :key="item.demandId"
                    >
                      {{ item.trainName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="item.key == 'hostDept'">
                <a-form-item :label="item.name" name="hostDepartmentId">
                  <a-input
                    v-model:value="formData.hostDepartmentName"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="item.key == 'implementDept'">
                <a-form-item :label="item.name" name="implementDepartmentId">
                  <a-input
                    v-model:value="formData.implementDepartmentName"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="item.key == 'trainingTime'">
                <a-form-item :label="item.name" name="trainingTime">
                  <a-range-picker
                    allowClear
                    v-model:value="formData.trainingTime"
                    format="YYYY-MM-DD HH:mm"
                    valueFormat="YYYY-MM-DD HH:mm"
                    style="width: 100%"
                    :show-time="{
                      hideDisabledOptions: true,
                    }"
                    :disabled="disabled"
                  >
                    <template #suffixIcon>
                      <CalendarOutlined />
                    </template>
                  </a-range-picker>
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="item.key == 'budgetAmount'">
                <a-form-item :label="item.name" name="budgetAmount">
                  <a-input-number
                    style="width: calc(100% - 130px)"
                    :min="0"
                    :precision="2"
                    v-model:value="formData.budgetAmount"
                    :disabled="disabled"
                  />
                  {{ $t("TenThousandYuan") }}
                </a-form-item>
              </a-col>
            </template>
            <template v-else>
              <a-col :md="12" :sm="24">
                <!-- 1:文本框 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-if="item.type == 1"
                >
                  <a-input
                    v-model:value="formData[item.key]"
                    allowClear
                    :maxlength="50"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 2:下拉框 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 2"
                >
                  <a-select
                    allowClear
                    labelInValue
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    v-model:value="formData[item.key]"
                    :options="item.fieldOptions"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 4:数值 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 4"
                >
                  <a-input-number
                    allowClear
                    v-model:value="formData[item.key]"
                    :precision="item.len"
                    style="width: 100%"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 5:日期组件 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 5"
                >
                  <a-date-picker
                    :getCalendarContainer="
                      (triggerNode) => triggerNode.parentNode
                    "
                    valueFormat="YYYY-MM-DD"
                    v-model:value="formData[item.key]"
                    allowClear
                    style="width: 100%"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 6:文本域 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-if="item.type == 6"
                >
                  <a-textarea
                    v-model:value="formData[item.key]"
                    allowClear
                    :maxlength="500"
                    showCount
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 7:时间组件 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 7"
                >
                  <a-date-picker
                    :getCalendarContainer="
                      (triggerNode) => triggerNode.parentNode
                    "
                    valueFormat="YYYY-MM-DD HH:mm"
                    :show-time="{ format: 'HH:mm' }"
                    format="YYYY-MM-DD HH:mm"
                    v-model:value="formData[item.key]"
                    allowClear
                    style="width: 100%"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 8:树 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 8"
                >
                  <a-tree-select
                    allowClear
                    labelInValue
                    v-model:value="formData[item.key]"
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
                    :tree-data="item.fieldOptions"
                    treeNodeFilterProp="title"
                    :multiple="item.isCheck == 1"
                    :treeCheckable="item.isCheck == 1"
                    :showCheckedStrategy="
                      item.isSelectChild == 1 ? 'SHOW_CHILD' : 'SHOW_ALL'
                    "
                    :replaceFields="{
                      children: 'children',
                      title: 'optionValue',
                      key: 'optionKey',
                      value: 'optionKey',
                    }"
                    treeDefaultExpandAll
                    :disabled="disabled"
                  ></a-tree-select>
                </a-form-item>
              </a-col>
            </template>
          </template>
        </a-row>
      </a-form>
      <div class="title-wrap">{{ $t("ProgramDetails") }}</div>
      <a-table
        size="small"
        :scroll="{ x: 884 }"
        :columns="state.subPlanColumns"
        rowKey="subPlanId"
        :data-source="state.dataList"
        :pagination="false"
      >
        <template #index="{ index }">
          {{ index + 1 }}
        </template>
        <template #tooltip="{ text }">
          <a-tooltip :title="text">{{ text }}</a-tooltip>
        </template>
        <template #date="{ text }">
          <template v-if="text && text.length">
            <a-tooltip :title="text[0] + '~' + text[1]"
              >{{ text[0] }} ~ {{ text[1] }}</a-tooltip
            >
          </template>
        </template>
        <template #member="{ text }">
          <a-tooltip placement="topLeft">
            <template #title>
              <template v-for="(item, index) in text" :key="index">
                <OpenData
                  class="pr6"
                  type="userName"
                  :openid="item.lecturerName || item.realName"
                  v-if="item.userId"
                />
                <span class="pr6" v-else>{{
                  item.lecturerName || item.realName
                }}</span>
              </template>
            </template>
            <div class="w160 ellipsis">
              <template v-for="(item, index) in text" :key="index">
                <OpenData
                  class="pr6"
                  type="userName"
                  :openid="item.lecturerName || item.realName"
                  v-if="item.userId"
                />
                <span class="pr6" v-else>{{
                  item.lecturerName || item.realName
                }}</span>
              </template>
            </div>
          </a-tooltip>
        </template>
        <template #budgetAmount="{ text }"
          >{{ text }}{{ $t("TenThousandYuan") }}</template
        >
        <template #tooltip_json="{ text }">
          <template v-if="text">
            <template v-if="Array.isArray(JSON.parse(text || '{}'))">
              <a-tooltip
                :title="
                  JSON.parse(text)
                    .map((o) => o.label)
                    .join(',')
                "
              >
                {{
                  JSON.parse(text)
                    .map((o) => o.label)
                    .join(",")
                }}
              </a-tooltip>
            </template>
            <a-tooltip v-else :title="JSON.parse(text || '{}').label">{{
              JSON.parse(text || "{}").label
            }}</a-tooltip>
          </template>
        </template>
      </a-table>
    </div>
    <div class="footer">
      <!-- 取消 -->
      <a-button @click="visible = false">{{ $t("CM_Cancel") }}</a-button>
    </div>
  </a-drawer>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive } from "vue";
import ls from "@/utils/local-storage";
import { getPlanField } from "@/api/auditCenter";
import OpenData from "@/components/OpenData.vue";

const { t: $t } = useI18n();
const visible = ref(false);

const state = reactive({
  editFields: [],
  demands: [],
  subPlanColumns: [
    {
      // title: "序号",
      title: $t("map.table.serial"),
      dataIndex: "index",
      width: 60,
      ellipsis: true,
      slots: { customRender: "index" },
    },
    {
      // title: "培训名称",
      title: $t("NameOfTraining"),
      dataIndex: "trainingName",
      width: 150,
      ellipsis: true,
      slots: { customRender: "tooltip" },
    },
    {
      // title: "培训时间",
      title: $t("Pub_Tab_PlannedTrainingTime"),
      dataIndex: "trainingTime",
      width: 320,
      ellipsis: true,
      slots: { customRender: "date" },
      align: "center",
    },
    {
      // title: "培训讲师",
      title: $t("TrainingLecturers"),
      dataIndex: "teacherList",
      width: 180,
      ellipsis: true,
      slots: { customRender: "member" },
    },
    {
      // title: "培训地点",
      title: $t("Lab_Evaluate_T_TrainingAddress"),
      dataIndex: "address",
      width: 150,
      ellipsis: true,
      slots: { customRender: "tooltip" },
    },
    {
      // title: "预算费用",
      title: $t("demand.budget"),
      dataIndex: "budgetAmount",
      width: 150,
      ellipsis: true,
      slots: { customRender: "budgetAmount" },
    },
    {
      // title: "培训负责人",
      title: $t("TrainingLeaders"),
      dataIndex: "responsibleUserList",
      width: 180,
      ellipsis: true,
      slots: { customRender: "member" },
    },
  ],
  dataList: [],
  companyInfo: {},
});
const formData = ref({
  planId: 0,
  secretData: 0,
  planName: "",
  demandCollectionId: 0,
  demandCollectionName: "",
  demandIds: [],
  hostDepartmentId: "",
  implementDepartmentId: "",
  trainingTime: [],
  budgetAmount: 0,
});
const disabled = ref(true);

function show(data) {
  state.companyInfo = ls.get("companyInfo") || {};
  visible.value = true;
  initData();
  formData.value = data;
  let demandList = data.demandList || [];
  state.demands = [];
  formData.value.demandIds = [];
  demandList.forEach((item) => {
    formData.value.demandIds.push(item.demandId);
    state.demands.push(item);
  });
  state.dataList = data.subPlanList || [];
}
async function initData() {
  const res = await getPlanField();
  if (res.ret !== 0) return false;
  let editFields = res.data.plan.editFields || [];
  editFields.forEach((item) => {
    if (item.defaultField == 2) {
      if (item.type == 2) {
        item.fieldOptions.map((opt) => {
          opt.label = opt.optionValue;
          opt.value = opt.optionKey;
        });
      }
      // if (item.type == 8 && item.isSelectChild == 1) {
      //   if (item.isCheck == 1) {
      //     isLeaf(item.fieldOptions, 'selectable');
      //   } else {
      //     isLeaf(item.fieldOptions, 'disabled');
      //   }
      // }
    }
  });
  state.editFields = editFields;

  let subEditFields = res.data.subPlan.editFields || [];
  subEditFields.forEach((item) => {
    if (item.defaultField == 2) {
      if (item.type == 2) {
        item.fieldOptions.map((opt) => {
          opt.label = opt.optionValue;
          opt.value = opt.optionKey;
        });
      }
      // if (item.type == 8 && item.isSelectChild == 1) {
      //   if (item.isCheck == 1) {
      //     isLeaf(item.fieldOptions, 'selectable');
      //   } else {
      //     isLeaf(item.fieldOptions, 'disabled');
      //   }
      // }
      let tplName = "tooltip";
      if (item.key.slice(7, 8) == 2 || item.key.slice(7, 8) == 8) {
        tplName = "tooltip_json";
      }
      state.subPlanColumns.push({
        title: item.name,
        dataIndex: "customFieldMap." + item.key,
        slots: { customRender: tplName },
        width: 180,
        sorter: false,
      });
    }
  });
}

defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.detail {
  width: 950px;
  margin: 0 auto;
  padding-bottom: 80px;
  .title-wrap {
    position: relative;
    padding: 10px;
    font-size: 16px;
    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: -2px;
      height: 16px;
      width: 4px;
      background-color: var(--theme);
    }
  }
}
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  padding: 10px 0;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #ccc;
}
.pr6 {
  padding-right: 6px;
}
.w160 {
  width: 160px;
}
</style>
